import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  darkPurple,
  innerWidth,
  mainWhite,
  mainPurple,
  screen,
  subFont,
  moveBgColor,
} from "../components/variables";
import CTA from "../components/cta";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Button from "../components/button";
import { getVimeoId } from "../components/helpers";
import TableauForm from "../components/form/tableau-form";
import DownloadForm from "../components/form/download-form-tableau";

const Wrapper = styled.div`
  padding: 60px 0 0 0;
  @media ${screen.xsmall} {
    padding: 100px 0 0 0;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--intro {
      @media ${screen.xsmall} {
        display: flex;
        align-items: center;
      }
    }

    &--brochure {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .hero {
    overflow: hidden;
    position: relative;
    @media ${screen.xsmall} {
      background-image: ${(props) => `url(${props.banner_background_image})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      min-height: 610px;
    }

    .gradient-bg {
      position: absolute;
      background-image: linear-gradient(110deg, #67b6ff, #3697ed, #6300ff);
      background-size: 350%;
      animation: ${moveBgColor} 5s infinite alternate;
      width: 100%;
      height: 100%;
      @media ${screen.xsmall} {
        position: absolute;
        width: 80%;
        height: 180%;
        left: -22%;
        top: 0;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text-wrapper {
      padding: 50px 25px 50px 25px;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        width: 60%;
        padding: 0 80px 0 0;
      }

      .text-subwrapper {
        max-width: 710px;
        margin: 0 0 0 auto;
        @media ${screen.xsmall} {
          padding: 0 50px 0 40px;
        }
        @media ${screen.medium} {
          padding: 0 50px 0 0;
        }

        .description {
          margin: 22px 0 32px 0;
          position: relative;
          z-index: 2;
          color: ${mainWhite};

          h1 {
            font-family: ${subFont};
            font-size: 2.38rem;
            color: ${darkPurple};
            line-height: 1.05;
            margin: 0 0 6px 0;
            @media ${screen.xsmall} {
              font-size: 3.7rem;
            }
            @media ${screen.small} {
              font-size: 3.9rem;
            }
            @media ${screen.medium} {
              font-size: 4.15rem;
            }

            em {
              color: ${mainWhite};
              font-style: normal;
            }

            strong {
              color: ${mainWhite};
              display: block;
              margin: 0 0 0 52px;
              @media ${screen.small} {
                margin: 0 0 0 152px;
              }
            }
          }

          p {
            font-size: 1.05rem;
            line-height: 1.4;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.5rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: calc(50% + 125px);
      transform: translateY(-50%);
      left: -120px;
      pointer-events: none;
      width: 500px;
      @media ${screen.xsmall} {
        width: 950px;
        left: -400px;
        top: 50%;
      }
      @media ${screen.small} {
        width: 900px;
        left: -250px;
      }
      @media ${screen.medium} {
        width: 1000px;
      }
      @media ${screen.large} {
        width: calc(750px + 12vw);
        left: -70px;
      }
    }

    .shine-bg {
      display: none;
      @media ${screen.xsmall} {
        display: block;
        position: absolute;
        right: -150px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(320px + 12vw);
        opacity: 0.25;
      }
    }
  }

  .intro {
    padding: 55px 0;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .col {
      &--img {
        @media ${screen.xsmall} {
          padding: 0 40px 0 0;
          width: 50%;
        }
        @media ${screen.small} {
          padding: 0 60px 0 0;
        }
        @media ${screen.medium} {
          padding: 0 80px 0 0;
        }
      }

      &--text {
        margin: 20px 0 0 0;
        @media ${screen.xsmall} {
          width: 50%;
          margin: 0;
        }

        .description {
          margin: 0 0 32px 0;

          h2 {
            font-family: ${subFont};
            font-size: 1.55rem;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              font-size: 1.8rem;
            }
            @media ${screen.small} {
              font-size: 1.9rem;
            }
            @media ${screen.medium} {
              font-size: 2rem;
            }

            strong {
              color: ${mainPurple};
              font-style: normal;
            }
          }

          p {
            font-size: 1.05rem;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }

            &:last-child {
              margin: 0;
            }
          }

          ul,
          ol {
            padding: 0 0 0 40px;
            @media ${screen.xsmall} {
              padding: 0 0 0 50px;
            }

            li {
              font-size: 1.05rem;
              margin: 0 0 21px 0;
              line-height: 1.4;
              @media ${screen.xsmall} {
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                font-size: 1.2rem;
              }
              @media ${screen.medium} {
                font-size: 1.25rem;
              }
            }
          }

          ul {
            li {
              list-style-type: disc;
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }

        .btn {
          button {
            background: ${darkPurple};
            color: ${mainWhite};
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainPurple};
              }
            }
          }
        }
      }
    }
  }

  .video {
    padding: 55px 0;
    background-image: linear-gradient(-90deg, #67b6ff, #3697ed, #6300ff);
    background-size: 450%;
    animation: ${moveBgColor} 5s infinite alternate;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: 40px;
      left: -25px;
      pointer-events: none;
      width: 650px;
      @media ${screen.xsmall} {
        width: 1000px;
        left: -150px;
        top: 100px;
      }
      @media ${screen.small} {
        width: 1190px;
      }
      @media ${screen.medium} {
        width: 1270px;
      }
      @media ${screen.large} {
        width: calc(1300px + 12vw);
        left: -70px;
      }
    }

    .description {
      color: ${mainWhite};
      max-width: 700px;
      text-align: center;
      margin: 0 auto 35px auto;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        margin: 0 auto 40px auto;
      }
      @media ${screen.medium} {
        margin: 0 auto 60px auto;
      }

      h2 {
        font-family: ${subFont};
        font-size: 1.55rem;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.8rem;
        }
        @media ${screen.small} {
          font-size: 1.9rem;
        }
        @media ${screen.medium} {
          font-size: 2rem;
        }

        strong {
          color: ${mainPurple};
        }
      }

      p {
        font-size: 1.05rem;
        margin: 0 0 21px 0;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }

        &:last-child {
          margin: 0;
        }
      }

      ul,
      ol {
        padding: 0 0 0 40px;
        @media ${screen.xsmall} {
          padding: 0 0 0 50px;
        }

        li {
          font-size: 1.05rem;
          margin: 0 0 21px 0;
          line-height: 1.4;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
          }
          @media ${screen.small} {
            font-size: 1.2rem;
          }
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
        }
      }

      ul {
        li {
          list-style-type: disc;
        }
      }

      ol {
        li {
          list-style-type: decimal;
        }
      }
    }

    .video-wrapper {
      max-width: 1000px;
      margin: 0 auto;
      position: relative;
      z-index: 3;

      .video-subwrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        .iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }

  .brochure {
    padding: 55px 0;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .col {
      &--img {
        order: -1;
        @media ${screen.xsmall} {
          padding: 0 0 0 40px;
          width: 50%;
          order: 0;
        }
        @media ${screen.small} {
          padding: 0 0 0 60px;
        }
        @media ${screen.medium} {
          padding: 0 0 0 80px;
        }
      }

      &--text {
        margin: 20px 0 0 0;
        @media ${screen.xsmall} {
          width: 50%;
          margin: 0;
        }

        .description {
          margin: 0 0 32px 0;

          h2 {
            font-family: ${subFont};
            font-size: 1.55rem;
            margin: 0 0 20px 0;
            @media ${screen.xsmall} {
              font-size: 1.8rem;
            }
            @media ${screen.small} {
              font-size: 1.9rem;
            }
            @media ${screen.medium} {
              font-size: 2rem;
            }

            strong {
              color: ${mainPurple};
              font-style: normal;
            }
          }

          p {
            font-size: 1.05rem;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
            }
            @media ${screen.small} {
              font-size: 1.2rem;
            }
            @media ${screen.medium} {
              font-size: 1.25rem;
            }

            &:last-child {
              margin: 0;
            }
          }

          ul,
          ol {
            padding: 0 0 0 40px;
            @media ${screen.xsmall} {
              padding: 0 0 0 50px;
            }

            li {
              font-size: 1.05rem;
              margin: 0 0 21px 0;
              line-height: 1.4;
              @media ${screen.xsmall} {
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                font-size: 1.2rem;
              }
              @media ${screen.medium} {
                font-size: 1.25rem;
              }
            }
          }

          ul {
            li {
              list-style-type: disc;
            }
          }

          ol {
            li {
              list-style-type: decimal;
            }
          }
        }

        .btn {
          a {
            background: ${darkPurple};
            color: ${mainWhite};
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainPurple};
              }
            }
          }
        }
      }
    }
  }
`;

const TableauPage = ({ data }) => {
  const [isTableauForm, setIsTableauForm] = useState(false);
  const [isDownloadForm, setIsDownloadForm] = useState(false);

  const {
    banner_background_image,
    banner_description,
    intro_description,
    intro_featured_image,
    brochure_description,
    brochure_download_file,
    brochure_featured_image,
    video_description,
    vimeo_url,
    cta_background_image,
    cta_description,
    title_tag,
    meta_description,
  } = data.main.data;

  const vimeoId = getVimeoId(vimeo_url);

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={intro_featured_image.url}
      />
      <Wrapper banner_background_image={banner_background_image.url}>
        <header className="hero">
          <div className="gradient-bg" role="presentation"></div>

          <div className="text-wrapper">
            <div
              className="text-subwrapper"
              data-sal="slide-right"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/arrows_home-about-banner-arrows.png"
              alt="Graphics"
              placeholder="blurred"
            />
          </div>

          <div className="shine-bg" role="presentation">
            <StaticImage
              src="../images/Tableau-LogoIcon-RGB.png"
              alt="Graphics"
              placeholder="blurred"
            />
          </div>
        </header>

        <section className="intro">
          <div className="inner-wrapper inner-wrapper--intro">
            <div
              className="col col--img"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <GatsbyImage
                image={intro_featured_image.thumbnails.cropped.gatsbyImageData}
                alt={intro_featured_image.alt || "Featured"}
              />
            </div>
            <div
              className="col col--text"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: intro_description.html }}
              />
              <Button
                className="btn"
                onClick={() => {
                  setIsDownloadForm(true);
                }}
              >
                DOWNLOAD NOW
              </Button>
            </div>
          </div>
        </section>

        <section className="video">
          <div className="inner-wrapper">
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
              dangerouslySetInnerHTML={{ __html: video_description.html }}
            />

            <div className="video-wrapper">
              <div
                className="video-subwrapper"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <iframe
                  className="iframe"
                  src={`https://player.vimeo.com/video/${vimeoId}`}
                />
              </div>
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/Tableau-WhatIsEmbedded-Arrows.png"
              alt="Graphic"
              placeholder="blurred"
            />
          </div>
        </section>

        <section className="brochure">
          <div className="inner-wrapper inner-wrapper--brochure">
            <div
              className="col col--text"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: brochure_description.html }}
              />
              <Button
                href={brochure_download_file.url}
                isNewTab={true}
                className="btn"
              >
                DOWNLOAD NOW
              </Button>
            </div>
            <div
              className="col col--img"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <GatsbyImage
                image={
                  brochure_featured_image.thumbnails.cropped.gatsbyImageData
                }
                alt={brochure_featured_image.alt || "Featured"}
              />
            </div>
          </div>
        </section>

        <CTA
          bgImg={cta_background_image.url}
          className="cta"
          html={cta_description.html}
          isTableauPage={true}
          setIsTableauForm={setIsTableauForm}
        />
      </Wrapper>

      <TableauForm
        isTableauForm={isTableauForm}
        setIsTableauForm={setIsTableauForm}
      />

      <DownloadForm
        isDownloadForm={isDownloadForm}
        setIsDownloadForm={setIsDownloadForm}
      />
    </Layout>
  );
};

export default TableauPage;

export const dataQuery = graphql`
  {
    main: prismicTableauPage {
      data {
        banner_background_image {
          url
        }
        banner_description {
          html
        }
        brochure_description {
          html
        }
        brochure_download_file {
          url
        }
        brochure_featured_image {
          alt
          thumbnails {
            cropped {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        cta_background_image {
          url
        }
        cta_description {
          html
        }
        intro_description {
          html
        }
        intro_featured_image {
          alt
          url
          thumbnails {
            cropped {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        video_description {
          html
        }
        vimeo_url
        meta_description
        title_tag
      }
    }
  }
`;
