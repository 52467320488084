import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import {
  subFont,
  mainWhite,
  transHover,
  mainPurple,
  screen,
  darkPurple,
} from "../variables";
import { navigate } from "gatsby";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { ScrollLocky } from "react-scroll-locky";
import Button from "../button";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -15 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: rgba(46, 41, 78, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;

  .container {
    background: ${mainWhite};
    border-radius: 16px;
    max-width: 870px;
    width: calc(100% - 50px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 25px 50px 25px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    @media ${screen.xsmall} {
      padding: 50px;
      border-radius: 20px;
      max-height: none;
    }

    @media ${screen.small} {
      border-radius: 25px;
    }

    @media ${screen.medium} {
      border-radius: 30px;
    }

    .description {
      max-width: 550px;
      margin: 0 0 35px 0;

      h3 {
        font-family: ${subFont};
        font-size: 1.25rem;
        margin: 0 0 6px 0;
        @media ${screen.xsmall} {
          font-size: 1.6rem;
          margin: 0 0 12px 0;
        }
        @media ${screen.small} {
          font-size: 1.7rem;
        }
        @media ${screen.medium} {
          font-size: 1.8rem;
        }

        strong {
          color: ${mainPurple};
          font-style: normal;
        }
      }

      p {
        font-size: 0.95rem;
        line-height: 1.4;
        @media ${screen.xsmall} {
          font-size: 1.2rem;
        }
      }
    }

    .form {
      width: 100%;
      @media ${screen.xsmall} {
        display: flex;
      }

      .col {
        .each-field {
          margin: 0 0 12px 0;
          @media ${screen.xsmall} {
            margin: 0 0 20px 0;
          }

          label {
            display: block;
            margin: 0 0 5px 0;
            line-height: 1.35;
            font-size: 0.9rem;
            @media ${screen.xsmall} {
              font-size: 1rem;
              margin: 0 0 8px 0;
            }
          }

          input {
            border: 0;
            display: block;
            width: 100%;
            border: 0;
            border-radius: 8px;
            background: #ece0ff;
            font-size: 16px;
            outline: none;
            padding: 11px 11px;
            @media ${screen.xsmall} {
            }
          }
        }

        .btn {
          margin: 25px 0 0 0;
          @media ${screen.xsmall} {
            margin: 35px 0 0 0;
          }

          button {
            background: ${darkPurple};
            color: ${mainWhite};
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainPurple};
              }
            }
          }
        }

        &--left {
          @media ${screen.xsmall} {
            width: 50%;
            padding: 0 20px 0 0;
          }

          .img {
            display: none;
            @media ${screen.xsmall} {
              display: block;
              margin: 26px 0 0 85px;
              max-width: 150px;
            }
          }
        }

        &--right {
          @media ${screen.xsmall} {
            width: 50%;
            padding: 0 0 0 20px;
          }
        }
      }
    }

    .close-btn {
      font-size: 1.96rem;
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.xsmall} {
        font-size: 2.16rem;
        right: 25px;
        top: 25px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }
`;

const TableauForm = ({ isTableauForm, setIsTableauForm }) => {
  const isActive = isTableauForm;

  const [userInput, setUserInput] = useState({});
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .send(
        "service_domfn04",
        "template_9gdv1cn",
        userInput,
        "user_6UcVTbn8hTvSYTC5xgwbL"
      )
      .then(() => {
        setIsSending(false);
        setUserInput({});
        navigate("/success-submission/");
      })
      .catch(() => {
        setIsSending(false);
        alert("Sorry, Something went wrong. Please contact us immediately.");
      });
  };

  return (
    <AnimatePresence>
      {isActive && (
        <Wrapper
          id="member-lightbox"
          initial="hidden"
          animate={isActive ? "show" : "hidden"}
          variants={container}
          exit={{ opacity: 0 }}
        >
          <ScrollLocky isolation={false}>
            <div className="container">
              <motion.div variants={item} className="description">
                <h3>
                  Your <strong>Tableau</strong> experience starts now.
                </h3>
                <p>
                  Submit the form below and we’ll get back to you to set up your
                  free Tableau trial.
                </p>
              </motion.div>
              <motion.form
                variants={item}
                className="form"
                onSubmit={handleSubmit}
              >
                <div className="col col--left">
                  <div className="each-field">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={userInput.first_name || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={userInput.last_name || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      value={userInput.email || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col col--right">
                  <div className="each-field">
                    <label>Phone Number</label>
                    <input
                      type="phone"
                      name="phone"
                      value={userInput.phone || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="each-field">
                    <label>Company Name</label>
                    <input
                      type="text"
                      name="company_name"
                      value={userInput.company_name || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field">
                    <label>Position in the Organisation</label>
                    <input
                      type="text"
                      name="position_in_the_organisation"
                      value={userInput.position_in_the_organisation || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <Button className="btn">
                    {isSending ? "SENDING..." : "SUBMIT NOW"}
                  </Button>
                </div>
              </motion.form>

              <p
                className="close-btn"
                role="button"
                onClick={() => {
                  setIsTableauForm(false);
                  setUserInput({});
                }}
              >
                <IoIosCloseCircleOutline />
              </p>
            </div>
          </ScrollLocky>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default TableauForm;
